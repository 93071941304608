import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function About() {
  return (
    <div style={{ padding: '60px'}}>
      <Container>
        <Row className="align-items-center">
          {/* Imagen */}
          <Col xs={12} md={4} className="text-center">
            <img
              src="./img/office2.png"
              height="400px"
              alt="img"
              style={{
                borderStyle: 'solid',
                borderColor: '#1a1a1a',
                padding: '15px',
                borderRadius: '8px',
              }}
            />
          </Col>

          {/* Texto */}
          <Col xs={12} md={8}>
            <h2
              style={{
                paddingTop: '15px',
                textAlign: 'center',
                fontFamily: 'Verdana',
                marginBottom: '20px',
              }}
            >
              AMPLIA EXPERIENCIA EN EL RUBRO CONTABLE Y FINANCIERO
            </h2>
            <div style={{ textAlign: 'left' , paddingLeft:'40px'}}>
              <p style={{ fontFamily: 'Verdana', lineHeight: '1.6' }}>
                En el competitivo panorama empresarial actual, las empresas buscan destacar y conectar de manera significativa con su audiencia. 
                Aquí es donde un proveedor de servicios creativos eficaz se convierte en un aliado indispensable. 
                La capacidad de un proveedor para ofrecer soluciones innovadoras y adaptadas a las necesidades específicas de cada empresa 
                puede marcar la diferencia entre el éxito y el estancamiento en el mercado.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
