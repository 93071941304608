import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

export default function banner() {
  return (
    <Carousel fade>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./img/office.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h1  style={{fontFamily:'Helvetica', color:'white', fontSize:'100px', textShadow:'4px 4px 4px grey'}}>DC CONSULTING</h1>
          <h1  style={{fontFamily:'Helvetica', color:'white', fontSize:'90px', textShadow:'4px 4px 4px grey'}}>Asesoria contable y financiera</h1>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./img/office4.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h1 style={{fontFamily:'Helvetica', color:'white', fontSize:'100px', textShadow:'4px 4px 4px grey'}}>DC CONSULTING</h1>
          <h1 style={{fontFamily:'Helvetica', color:'white', fontSize:'90px', textShadow:'4px 4px 4px grey'}}>Asesoria contable y financiera</h1>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  )
}
