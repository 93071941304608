import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Info() {
  return (
    <section className="block blog-block" style={{ padding: '40px' }}>
      {/* Título principal */}
      <h1 style={{ textAlign: 'center', fontFamily: 'Verdana', marginBottom: '40px' }}>
        Contamos con los siguientes servicios
      </h1>

      <Container fluid="md" style={{ paddingLeft: '100px'}}>
        {/* Primera fila */}
        <Row className="justify-content-md-center mb-4">
          <Col>
            <Card style={{ width: '27rem', height: '20rem', textAlign: 'center' }}>
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Verdana', marginBottom: '20px' }}>
                  Construcciones
                </Card.Title>
                <img src="./img/camion.png" height="90px" alt="camion" />
                <Card.Text style={{ fontFamily: 'Verdana', padding: '10px' }}>
                  Ofrecemos construcciones a medida, enfocándonos en soluciones robustas y eficientes que impulsan tu institución. 
                  Nuestro equipo garantiza que cada proyecto esté alineado con tus objetivos y optimizado para el mejor rendimiento.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ width: '27rem', height: '20rem', textAlign: 'center' }}>
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Verdana', marginBottom: '20px' }}>
                  Tecnologías
                </Card.Title>
                <img src="./img/technology.png" height="70px" alt="technology" />
                <Card.Text style={{ fontFamily: 'Verdana', padding: '10px' }}>
                  Ofrecemos estrategias de marketing diseñadas para maximizar tu alcance y conectar con tu audiencia. 
                  Nuestro enfoque creativo y orientado a resultados asegura el crecimiento de tu marca en un mercado competitivo.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Segunda fila */}
        <Row className="justify-content-md-center">
          <Col className="mb-4">
            <Card style={{ width: '27rem', height: '20rem', textAlign: 'center' }}>
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Verdana', marginBottom: '20px' }}>
                  Asistencia personalizada
                </Card.Title>
                <img src="./img/support.png" height="60px" alt="support" />
                <Card.Text style={{ fontFamily: 'Verdana', padding: '10px' }}>
                  Optimiza tus campañas con nuestro call center. 💡 Conectamos a tus clientes de manera eficiente, 
                  garantizando una experiencia personalizada y resultados medibles. 
                  ¡Eleva tu comunicación al siguiente nivel!
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="mb-4">
            <Card style={{ width: '27rem', height: '20rem', textAlign: 'center' }}>
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Verdana', marginBottom: '20px' }}>
                  Rendimiento
                </Card.Title>
                <img src="./img/performance.png" height="70px" alt="performance" />
                <Card.Text style={{ fontFamily: 'Verdana', padding: '10px' }}>
                  A medida que su negocio prospera, nosotros crecemos como negocio y por eso 
                  nuestro equipo se asegura de que su crecimiento sea respaldado y vamos de la mano.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
