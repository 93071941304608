import React from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                {/* Título a la izquierda */}
                <Link to="/" className="navbar-brand" style={{paddingLeft:'20px'}}>
                    DC CONSULTING
                </Link>

                {/* Botón para dispositivos móviles */}
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                {/* Links a la derecha */}
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link to="/Nosotros" className="nav-link active">Nosotros</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Contacto" className="nav-link active">Contacto</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
