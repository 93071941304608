import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

export default function contact() {
  return (
    <div style={{padding:'60px'}} >
        <h1 style={{paddingBottom:'15px', textAlign:'center'}} >Contactanos</h1>
        <Container fluid="lg" style={{paddingTop:'50px', paddingLeft:'140px'}}>
          <Row className="justify-content-md-center">
            <Col>
              <img src='./img/phone-call.png' height={'100px'} alt='tel' style={{paddingLeft:'70px'}}></img>
              <h2 style={{paddingTop:'10px', fontFamily:'Verdana'}}>11 5236-0005</h2>
            </Col>
            <Col>
              <img src='./img/email.png' height={'100px'} alt='email' style={{paddingLeft:'215px'}}></img>
              <h2 style={{paddingTop:'10px', fontFamily:'Verdana'}}>CONTACTO@DCCONSULTING.AR</h2>
            </Col>
          </Row>
        </Container>
      </div>
  )
}

