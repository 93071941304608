import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Contact, About, Footer, Info, Banner, Navbar } from "./components";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function App() {
    const location = useLocation(); // Detecta la ruta actual

    // Verifica si la ruta actual es específica para un componente
    const isContactPage = location.pathname === "/Contacto";
    const isAboutPage = location.pathname === "/Nosotros";

    return (
        <div className="App">
            <main style={{ background: "#E5E9EC" }}>
                <Navbar />
                <Banner />
                <Routes>
                    {isContactPage && (
                        <Route path="/Contacto" element={<Contact />} />
                    )}
                    {isAboutPage && (
                        <Route path="/Nosotros" element={<About />} />
                    )}
                </Routes>
                {/* Renderiza los componentes según la ruta */}
                {!isContactPage && !isAboutPage && (
                    <>
                        <Container>
                          <Row>
                            <Col>
                              <Info />
                              <About />
                              <Contact />
                            </Col>
                          </Row>
                        </Container>
                    </>
                )}
                <Footer />
            </main>
        </div>
    );
}

export default App;
