import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function footer() {
  return (
    <div style={{backgroundColor:'#131313', display:'flex', padding:'20px'}}>
      <Row>
        <Col>
            <div style={{textAlign:'left', padding:'4vh', color:'white'}}>
                <h6 style={{paddingLeft:'10px', fontFamily:'Verdana'}}>Seguinos</h6>
                <a href='https://www.facebook.com'><img src='./img/social.png' height={'60px'} alt='fb' style={{paddingRight:'10px'}}></img></a>
                <a href='https://www.instagram.com'><img src='./img/instagram.png' height={'50px'} alt='ig' style={{paddingRight:'10px'}}></img></a>
            </div>
        </Col>
      </Row>
      <Col>
           <div style={{textAlign:'right', paddingTop:'10px',paddingLeft:'140vh', color:'white'}}>
                <h6 style={{fontFamily:'Verdana'}}>Cuit: 27-34174591-3</h6>
                <h6 style={{fontFamily:'Verdana'}}>Yamila Ayelen Diaz</h6>
                <h6 style={{fontFamily:'Verdana'}}>Direccion: CATAMARCA 113 CP:1865</h6>
                <h6  style={{fontFamily:'Verdana'}}>San Vicente, Provincia de Buenos Aires, Argentina</h6>
            </div>
        </Col>
    </div>
  )
}
